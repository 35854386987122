import React from 'react'
import "../scss/OurSpecials.scss"

function BottomSection(props) {
    return (
        <div className="aboutParent">
            <div className="aboutTop">{props.data.sectionTitle}</div>
            <div className="aboutDealer">{props.data.ourTitle}</div>
            <div className="aboutText" dangerouslySetInnerHTML={{ __html: props.data.ourParagraph }}></div>
        </div>
    )
}

export default BottomSection