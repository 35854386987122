import React from "react"
import Layout from '../components/layout'
import FinanceCard from '../components/FinanceCard'
import '../scss/Specials.scss'
import BottomSection from "../components/BottomSection"
import SEO from '../components/SEO'
import HTML from '../components/HTML'

function Financing(props) {
    const { pageContext } = props
    const { pageContent, theme, dealerInfo } = pageContext

    let components = [
        <BottomSection key={"BottomSection"} group={dealerInfo} data={pageContent.BottomSection} theme={theme} />, 
        <SEO key={"SEO"} title={"Financing"} data={pageContent.SEO} />
    ]

    let sortedComponents = components.sort((a, b) => parseFloat(a.props.data.order) - parseFloat(b.props.data.order)) // sorts the components based on json data

    let visibleComponents = sortedComponents.filter(component => { // filters out the components that are not visible
        return component.props.data.visible === true
    })

    return (
        <Layout>
            <HTML key={"HTML"} data={pageContent.HTML} />
            <div className="topHeader" style={{ backgroundImage: "url('https://cdn.mxsapi.com/Symes/finance.png')" }}></div>
            <div className='middle-main'>Finance Center</div>
            <FinanceCard group={dealerInfo} theme={theme} />
            {visibleComponents}
        </Layout>
    )
}

export default Financing
